import Swiper from 'swiper';
import { FreeMode, Navigation, Thumbs, EffectCreative, EffectFade, Controller, Autoplay } from "swiper/modules";
import { animate } from "motion";

(function () {
  'use strict';

  document.addEventListener('DOMContentLoaded', handleDOMContentLoaded);
  const letters = document.querySelectorAll('.secret__opera');
  const randomLetters = [];

  function handleDOMContentLoaded() {
    const thumbSwiper = new Swiper('.slider__thumb', {
      modules: [ FreeMode ],
      loop: true,
      slidesPerView: 2,
      freeMode: true,
      watchSlidesProgress: true
    });

    const textSwiper = new Swiper('.slider__text', {
      modules: [ EffectFade ],
      speed: 700,
      slidesPerView: 1,
      loop: true,
      effect: "fade",
      enabled: false,
      fadeEffect: {
        crossFade: true
      }
    });

    new Swiper('.slider__media', {
      modules: [ Navigation, Thumbs, EffectCreative, Controller, Autoplay ],
      speed: 700,
      controller: {
        control: textSwiper,
      },
      effect: "creative",
      loop: true,
      autoplay: {
        delay: 6000,
        pauseOnMouseEnter: true
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: thumbSwiper
      },
      creativeEffect: {
        prev: {
          shadow: true,
          translate: ["-20%", 0, -1],
        },
        next: {
          translate: ["100%", 0, 0],
        },
      }
    });

    letters.forEach((element) => {
      randomLetters.push(element);
    });

    shuffle(randomLetters);
    randomLetters.forEach((element, index) => {
      animate(element, { "--opacity": 1 }, { duration: 3, repeat: Infinity, repeatType: "reverse", repeatDelay: 5, delay: 5 * index })
    });
  }

  function shuffle(array) {
    let currentIndex = array.length;

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {

      // Pick a remaining element...
      let randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  }
})();
